<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="8"
      md="4"
    >
      <v-form
        @submit="submitRegionId"
        v-model="valid"
        ref="regionForm"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Region Selector</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-autocomplete
              placeholder="Select your region"
              :items="regionList"
              :item-value="item => item.id"
              :item-text="item => item.name"
              :rules="[v => !!v || 'You must choose a region']"
              v-model="selectedRegionId"
              autofocus
            ></v-autocomplete>
            <v-checkbox
              v-model="updateDefault"
              label="Set as default region"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              type="submit"
              @click.stop.prevent="submitRegionId"
              color="primary"
              :disabled="!  valid"
            >View Region</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RegionSelector',
  data: function () {
    return {
      selectedRegionId: '',
      valid: false,
      updateDefault: typeof localStorage.defaultRegion === 'undefined'
    }
  },
  computed: {
    ...mapState('instore', ['regions']),
    regionList: function () {
      return Object.keys(this.regions).map(item => {
        return {
          id: item,
          name: 'Region ' + item
        }
      })
    }
  },
  methods: {
    submitRegionId: function () {
      if (this.$refs.regionForm.validate()) {
        if (this.updateDefault) {
          localStorage.defaultRegion = this.selectedRegionId
          this.$emit('defaultsUpdated')
        }
        this.$router.push({
          path: `/${this.selectedRegionId}/region-details`
          // query: {
          //   storeIds: this.regions[this.selectedRegionId]
          // }
        })
      } else {
        console.log('Not valid')
      }
    }
  },
  async mounted () {
    if (Object.keys(this.regions).length === 0) {
      const token = await this.$auth.getTokenSilently()
      this.$store.dispatch('instore/loadRegionList', { token })
    }
  }
}
</script>

<style scoped>

</style>
