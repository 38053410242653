<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="8"
      md="4"
    >
      <v-form
        @submit="submitStoreId"
        v-model="valid"
        ref="storeForm"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Custom Region Selector</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
              <v-autocomplete
                multiple
                placeholder="Select your stores"
                :items="stores"
                :item-value="item => item.storeId"
                :item-text="item => item.storeName"
                :rules="[v => !!v || 'You must choose a store',
                        v => v.length > 0 || 'You must select at least one store']"
                v-model="selectedStoreIds"
                autofocus
              ></v-autocomplete>
            <v-checkbox
              v-model="saveRegion"
              label="Save Custom Region"
            ></v-checkbox>
            <v-text-field
              v-model="regionName"
              label="Custom Region Name"
              v-if="saveRegion"
              :rules="[v => !!v || 'You must enter a name']"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              type="submit"
              @click.stop.prevent="submitStoreId"
              color="primary"
              :disabled="!valid"
            >View Custom Region</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CustomRegionSelector',
  data: function () {
    return {
      selectedStoreIds: [],
      valid: false,
      saveRegion: false,
      regionName: ''
    }
  },
  computed: {
    ...mapState('instore', ['stores'])
  },
  methods: {
    submitStoreId: function () {
      if (this.$refs.storeForm.validate()) {
        // do we need to save the custom region
        if (this.saveRegion) {
          this.$emit('saveCustomRegion', {
            name: this.regionName,
            stores: this.selectedStoreIds
          })
        }
        this.$router.push(`/region-details?storeIds=${this.selectedStoreIds}`)
      } else {
        console.log('Not valid')
      }
    }
  }
}
</script>

<style scoped>

</style>
