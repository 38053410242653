<template>
  <div class="region my-4">
    <CustomRegionSelector
      @saveCustomRegion="args => $emit('saveCustomRegion', args)"
    ></CustomRegionSelector>
  </div>
</template>

<script>
import CustomRegionSelector from '../components/CustomRegionSelector'
export default {
  name: 'RegionSelectorView',
  components: { CustomRegionSelector },
  data () {
    return {
    }
  }
}
</script>
