<template>
  <div class="region my-4">
    <RegionSelector
      @defaultsUpdated="$emit('defaultsUpdated')"
    ></RegionSelector>
  </div>
</template>

<script>
import RegionSelector from '../components/RegionSelector'
export default {
  name: 'RegionSelectorView',
  components: { RegionSelector },
  data () {
    return {
    }
  }
}
</script>
